import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import Link from '~components/Link';

import BackgroundImg from '~images/other/flow-background.svg';

const FlowLayout = ({
  children,
  title,
  subtitle,
  to,
  linkTitle,
  redirectTo,
  redirectState,
  ...rest
}) => {
  const child = React.Children.toArray(children);
  return (
    <Container size={100} center="vertical" background={`url(${BackgroundImg})`} {...rest}>
      <Grid container direction="row" justify="space-evenly" alignItems="center" spacing={2}>
        <Grid item xs={12} md={5}>
          <Typography variant="h4" component="h2">
            {title}
          </Typography>

          {subtitle && (
            <Typography variant="h6" component="h3">
              {subtitle}{' '}
              {to && linkTitle && (
                <Link to={to} state={{ redirectTo, redirectState }}>
                  {linkTitle}
                </Link>
              )}
            </Typography>
          )}

          {child[0] && child[0]}
        </Grid>
        <Grid item xs={12} md={5}>
          {child[1] && child[1]}
        </Grid>
      </Grid>
    </Container>
  );
};

FlowLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  to: PropTypes.string,
  linkTitle: PropTypes.string,
  redirectTo: PropTypes.string,
  redirectState: PropTypes.string
};

FlowLayout.defaultProps = {
  subtitle: null,
  to: null,
  linkTitle: null,
  redirectTo: null,
  redirectState: null
};

export default FlowLayout;
