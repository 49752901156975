import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

import Page from '~layout/Page';

import FlowLayout from '~components/FlowLayout';
import { ButtonSubmit } from '~components/form-buttons';

const ThankYouDonation = () => (
  <Page>
    <FlowLayout title="Thank you so much for your donation to Aleph Beta!">
      <>
        <ButtonSubmit type="button" component={GatsbyLink} to="/">
          Explore Aleph Beta
        </ButtonSubmit>
      </>
    </FlowLayout>
  </Page>
);

export default ThankYouDonation;
